import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppContractLifecycleManagementSecondSectionWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .ContractHeading {
    font-size: 92px;
    color: #34a1d5;
    /* text-align: left; */
    /* padding: 0px 0px 10px 0px; */
  }
  .ContractKeyPara {
    /* text-align: left; */
    max-width: 1200px;
    /* margin: auto; */
    display: flex;
    font-weight: bolder;
    color: #fff;
    /* padding-top: 24px; */
    /* padding-bottom: 50px; */
    /* margin: 0px 200px 0px 200px; */
    @media (max-width: 1090px) {
      padding: 0px 15px 50px 15px;
    }
  }
  /* @media (max-width: 1920px) {
      padding: 0px 15px 50px 15px;
      margin: 0px 0px 0px 400px;
    }
    @media (max-width: 1650px) {
      padding: 0px 15px 50px 15px;
      margin: 0px 0px 0px 250px;
    }
    @media (max-width: 1440px) {
      padding: 0px 15px 50px 15px;
      margin: 0px 180px 0px 180px;
    } */

  .ContractSecondSectionContainer {
    /* background-image: url(/img/payable-banner-3.png);
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; */
    height: 33vh;
    background-color: #021d2d;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    @media (max-width: 440px) {
      display: flex;
      flex-direction: column;
      height: auto;
    }
  }
  .ContractSecondSection {
    padding-right: 24px;
    padding-left: 24px;
  }
`

export const AppContractLifecycleManagementSecondSection = () => {
  return (
    <AppContractLifecycleManagementSecondSectionWapper>
      <div className="ContractSecondSectionContainer">
        <div className="ContractSecondSection">
          <ReUsePtag
            para="Increased operational efficiency through streamlined processes up to"
            paraStyle="ContractKeyPara"
          />
          <ReUseHTwotag Heading="40%" HeadingStyle="ContractHeading" />
        </div>
        <div className="ContractSecondSection">
          <ReUsePtag para="Achieve ROI cost savings through optimized contracts of" paraStyle="ContractKeyPara" />
          <ReUseHTwotag Heading="20%" HeadingStyle="ContractHeading" />
        </div>
        <div className="ContractSecondSection">
          <ReUsePtag
            para="Negotiated savings via enhanced supplier relationships yield an additional"
            paraStyle="ContractKeyPara"
          />
          <ReUseHTwotag Heading="15%" HeadingStyle="ContractHeading" />
        </div>
      </div>
    </AppContractLifecycleManagementSecondSectionWapper>
  )
}
