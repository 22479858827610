import React, { useEffect } from 'react'
import { Layout } from '../../components/AppLayout'
import styled from 'styled-components'
import Scroll from 'react-scroll'
import { RDHelmet } from '../../components/RDHelmet'
import { AppContractLifecycleManagementBookDemo } from '../../components/AppContractLifecycleManagementBookDemo'
import { AppContractLifecycleManagementBanner } from '../../components/AppContractLifecycleManagementBanner'
import { AppContractLifecycleManagementSecondSection } from '../../components/AppContractLifecycleManagementSecondSection'
import { AppContractLifecycleManagementHoverSidebar } from '../../components/AppContractLifecycleManagementHoverSidebar'

var scroll = Scroll.animateScroll

const scrollData = {
  ByCompany: 700,
  FreeInstance: 900,
  SmallEnterprise: 1500,
  MediumEnterprise: 3000,
  LargeEnterprise: 4300,
  ByRoles: 5700,
  Executives: 5900,
  Procurement: 7400,
  Finance: 8600,
}

const scrollToIndex = (location: string) => {
  const item = Object.entries(scrollData).find((item) => item[0] == location)
  if (item && item[1]) {
    scroll.scrollTo(item[1])
  }
}

const ContractLifecycleManagementWrapper = styled.div`
  body: {
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

const ContractLifecycleManagement = (props: any) => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '1-408-899-2224', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' Santa Clara',
      addressRegion: ' 2350 Mission College Boulevard, CA',
      postalCode: '95054',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  useEffect(() => {
    if (props.location.state && props.location.state.scroll) {
      scrollToIndex(props.location.state.scroll)
    }
  }, [])

  return (
    <Layout scrollToIndex={scrollToIndex}>
      <RDHelmet
        subtitle={'Contract Management'}
        contentDescription="Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow."
        contentKeywords="content='Business spend management software, SaaS spend management software, Enterprise spend management software, Spend management software market, Spend management software companies, Business spend management platform, Artificial intelligence software, AI-based spend management solution'"
        contentOG="AI Powered Source-to-Pay SaaS Platform | Try Raindrop Free"
        contentOgDescription="Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow."
        contentOgUrl="https://raindrop.com/solutions/ContractLifecycleManagement/"
        schemaMarkup={schemaMarkup}
      />
      <ContractLifecycleManagementWrapper>
        <AppContractLifecycleManagementBanner />
        <AppContractLifecycleManagementSecondSection />
        <AppContractLifecycleManagementHoverSidebar />
        <AppContractLifecycleManagementBookDemo />
      </ContractLifecycleManagementWrapper>
    </Layout>
  )
}

export default ContractLifecycleManagement
